import React from "react";
import ThirdButton from "../buttons/ThirdButton";
import logoOne from "../../images/logo-one.png";
import { useNavigate } from "react-router-dom";
import "./styles/gradient.css";
const Gradient = () => {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate("/wait_list");
  };
  return (
    <>
      <div className="container-start">
        <img src={logoOne} alt="Logo" />
        <h2>Comienza con ONE running.</h2>
        <p>Corre con técnología orgullosamente latinoamerícana.</p>
      </div>
    </>
  );
};
export default Gradient;
