import React from "react";

const NoFoundPage = () => {
 

  return (
    
   <h1>404
    Not Found
   </h1>
    
  );
};
export default NoFoundPage;