import React from "react";

const Profiles = ({ img, name, title, position, descriptión }) => {
  return (
    <>
      <div className="card-profile">
        <div className="container-profile">
          <img src={img} alt="Imagen Perfil" />
          <div className="text-profile">
            <h3>{name}</h3>
            <h5>{title}</h5>
            <h4>{position}</h4>
          </div>
        </div>
        <div className="descrition-card">
          <p>{descriptión}</p>
        </div>
        <div className="container-max">
          <span>+</span>
        </div>
      </div>
    </>
  );
};
export default Profiles;
