import React from "react";
import "./styles/list.css"

const List = ({ img, title, description, subdescription }) => {
  return (
    <>
      <div className="item-list">
        <img src={img} alt="Imagen de la Lista" />
        <div className="text-list">
          <h4>{title}</h4>
          <p>{description}</p>
          <span>{subdescription}</span>
        </div>
      </div>
    </>
  );
};
export default List;
