import React from "react";
import "./styles/footer.css";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="info-footer">
          <p>Latinoamerica (Español)</p>
          <span>
            Copyright © 2022 C3 Sports Corp. Todos los derechos reservados.
          </span>
        </div>
        <div className="rules">
          <a href="privacy-policy">Política de privacidad</a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
