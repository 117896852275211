import React from "react";

const Profiles = ({ text }) => {
  return (
    <>
      <div className="text-container-color">
        <h2>{text}</h2>
      </div>
      <div className="container-max-white">
        <span>+</span>
      </div>
    </>
  );
};
export default Profiles;
