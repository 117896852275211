import React from "react";
import graph from "../../../../images/graph21k.png";

const TableTwenty = ({ hours, seconds, minutes, altitud }) => {
  const pace = (hours + minutes / 60 + seconds / 3600) / 24 / 10;

  const a = (6 / 60 + 25 / 3600) / 24;
  const b = (5 / 60 + 44 / 3600) / 24;
  const c = (5 / 60 + 7 / 3600) / 24;
  const d = (4 / 60 + 34 / 3600) / 24;
  const e = (4 / 60 + 0 / 3600) / 24;

  let porcentajeRef = 0;

  if (pace <= e) {
    porcentajeRef = 0.95;
  } else if (pace <= d) {
    porcentajeRef = 0.93;
  } else if (pace <= c) {
    porcentajeRef = 0.9;
  } else if (pace <= b) {
    porcentajeRef = 0.88;
  } else if (pace <= a) {
    porcentajeRef = 0.86;
  } else {
    porcentajeRef = 0.84;
  }

  const perdidaAltitud =
    1 -
    (1 -
      0.00401 * (2.64 - altitud * 0.001) -
      0.0117 * (2.64 - altitud * 0.001) * (2.64 - altitud * 0.001));

  const perdidaPorTemperatura = 0.01;

  const perdidaPorViento = 0;

  const paceRef =
    pace *
    (2 - porcentajeRef) *
    (1 + perdidaAltitud) *
    (1 + perdidaPorTemperatura) *
    (1 + perdidaPorViento);

  const decimalAHora = (decimal) => {
    var n = new Date(0, 0);
    n.setSeconds(+decimal * 24 * 60 * 60);
    return n.toTimeString().slice(0, 8);
  };

  const ritmo1 = paceRef * 1.02;
  const ritmo2 = paceRef * 1.02 * 1.02;
  const ritmo3 = paceRef * 1.02 * 1.03;
  const ritmo456 = paceRef * 1.02 * 1.04; //el mismo ritmo 4 5 6
  const ritmo7 = paceRef * 1.02 * 1.05;
  const ritmo810 = paceRef * 1.02 * 0.98; //el ritmo 8 10
  const ritmo9 = paceRef * 1.02 * 0.99;
  const ritmo111314 = paceRef * 0.98 * 0.98; //el ritmo 11 13 14
  const ritmo12 = paceRef * 0.98 * 0.96;
  const ritmo15 = paceRef * 0.98 * 1;
  const ritmo161718192021 = paceRef * 0.98 * 1.015; //el ritmo 16 17 18 19 20 21

  const tiempo2 = ritmo1 + ritmo2;
  const tiempo3 = tiempo2 + ritmo3;
  const tiempo4 = tiempo3 + ritmo456;
  const tiempo5 = tiempo4 + ritmo456;
  const tiempo6 = tiempo5 + ritmo456;
  const tiempo7 = tiempo6 + ritmo7;
  const tiempo8 = tiempo7 + ritmo810;
  const tiempo9 = tiempo8 + ritmo9;
  const tiempo10 = tiempo9 + ritmo810;
  const tiempo11 = tiempo10 + ritmo111314;
  const tiempo12 = tiempo11 + ritmo12;
  const tiempo13 = tiempo12 + ritmo111314;
  const tiempo14 = tiempo13 + ritmo111314;
  const tiempo15 = tiempo14 + ritmo15;
  const tiempo16 = tiempo15 + ritmo161718192021;
  const tiempo17 = tiempo16 + ritmo161718192021;
  const tiempo18 = tiempo17 + ritmo161718192021;
  const tiempo19 = tiempo18 + ritmo161718192021;
  const tiempo20 = tiempo19 + ritmo161718192021;
  const tiempo21 = tiempo20 + ritmo161718192021;
  const tiempo211 = tiempo21 + ritmo161718192021 / 10;

  return (
    <>
      <div className="container-21k">
        <img src={graph} alt="Grafica 21.1 k" />
        <div className="time">
          <h5>Tiempo estimado de carrera</h5>
          <h2>{decimalAHora(tiempo211)}</h2>
        </div>
        <table className="table-calculate table-bordered">
          <thead>
            <tr>
              <th scope="col">kilómetro</th>
              <th scope="col">Ritmo</th>
              <th scope="col">Tiempo</th>
            </tr>
          </thead>
          <tbody>
            <tr className="yellow">
              <th scope="row">1</th>
              <td>{decimalAHora(ritmo1)}</td>
              <td>{decimalAHora(ritmo1)}</td>
            </tr>
            <tr className="yellow">
              <th scope="row">2</th>
              <td>{decimalAHora(ritmo2)}</td>
              <td>{decimalAHora(tiempo2)}</td>
            </tr>
            <tr className="yellow">
              <th scope="row">3</th>
              <td>{decimalAHora(ritmo3)}</td>
              <td>{decimalAHora(tiempo3)}</td>
            </tr>
            <tr className="red">
              <th scope="row">4</th>
              <td>{decimalAHora(ritmo456)}</td>
              <td>{decimalAHora(tiempo4)}</td>
            </tr>
            <tr className="red">
              <th scope="row">5</th>
              <td>{decimalAHora(ritmo456)}</td>
              <td>{decimalAHora(tiempo5)}</td>
            </tr>
            <tr className="red">
              <th scope="row">6</th>
              <td>{decimalAHora(ritmo456)}</td>
              <td>{decimalAHora(tiempo6)}</td>
            </tr>
            <tr className="red">
              <th scope="row">7</th>
              <td>{decimalAHora(ritmo7)}</td>
              <td>{decimalAHora(tiempo7)}</td>
            </tr>
            <tr className="green">
              <th scope="row">8</th>
              <td>{decimalAHora(ritmo810)}</td>
              <td>{decimalAHora(tiempo8)}</td>
            </tr>
            <tr className="green">
              <th scope="row">9</th>
              <td>{decimalAHora(ritmo9)}</td>
              <td>{decimalAHora(tiempo9)}</td>
            </tr>
            <tr className="green">
              <th scope="row">10</th>
              <td>{decimalAHora(ritmo810)}</td>
              <td>{decimalAHora(tiempo10)}</td>
            </tr>
            <tr className="green">
              <th scope="row">11</th>
              <td>{decimalAHora(ritmo111314)}</td>
              <td>{decimalAHora(tiempo11)}</td>
            </tr>
            <tr className="green">
              <th scope="row">12</th>
              <td>{decimalAHora(ritmo12)}</td>
              <td>{decimalAHora(tiempo12)}</td>
            </tr>
            <tr className="green">
              <th scope="row">13</th>
              <td>{decimalAHora(ritmo111314)}</td>
              <td>{decimalAHora(tiempo13)}</td>
            </tr>
            <tr className="green">
              <th scope="row">14</th>
              <td>{decimalAHora(ritmo111314)}</td>
              <td>{decimalAHora(tiempo14)}</td>
            </tr>
            <tr className="green">
              <th scope="row">15</th>
              <td>{decimalAHora(ritmo15)}</td>
              <td>{decimalAHora(tiempo15)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">16</th>
              <td>{decimalAHora(ritmo161718192021)}</td>
              <td>{decimalAHora(tiempo16)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">17</th>
              <td>{decimalAHora(ritmo161718192021)}</td>
              <td>{decimalAHora(tiempo17)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">18</th>
              <td>{decimalAHora(ritmo161718192021)}</td>
              <td>{decimalAHora(tiempo18)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">19</th>
              <td>{decimalAHora(ritmo161718192021)}</td>
              <td>{decimalAHora(tiempo19)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">20</th>
              <td>{decimalAHora(ritmo161718192021)}</td>
              <td>{decimalAHora(tiempo20)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">21</th>
              <td>{decimalAHora(ritmo161718192021)}</td>
              <td>{decimalAHora(tiempo21)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">21.1</th>
              <td>{decimalAHora(ritmo161718192021)}</td>
              <td>{decimalAHora(tiempo211)}</td>
            </tr>
          </tbody>
        </table>
        <div className="container container-conditions">
          <h6>Condiciones de carrera</h6>
          <div className="row conditions">
            <div className="col-6 container-p">
              <p>Distancia de Carrera: 21.1km</p>
              <p>Distancia por GPS: 21.5 km</p>
              <p>Altitud de la carrera: 2600 msnm</p>
              <p>Desnivel positivo: 105 m</p>
            </div>
            <div className="col-6 container-p">
              <p>Temperatura: 15°C a 17°C</p>
              <p>Humedad: 67%</p>
              <p>Viento: 16 km/h</p>
              <p>Probabilidad de lluvia: 30%</p>
            </div>
          </div>
          <span>
            La estrategia de carrera incluye las variables más importantes que
            afectan tu rendimiento, como altitud, altimetría, temperatura,
            humedad y viento.
          </span>
        </div>
      </div>
    </>
  );
};

export default TableTwenty;
