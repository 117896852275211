import React from "react";
import Profiles from "./Profiles";
import AboutTexts from "./AboutTexts";
import "../styles/about.css";
import logoAbout from "../../../images/logo-about.png";
import andres from "../../../images/img-andres.png";
import sergio from "../../../images/img-sergio.png";
import luis from "../../../images/img-luis.png";
import daniel from "../../../images/img-daniel.png";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Gradient from "../../globals/Gradient";

const AboutUs = () => {
  return (
    <>
      <Header />
      <div className="container-about">
        <div className="container-about-banner">
          <img src={logoAbout} alt="Logo" className="logo-about" />
          <h2 className="text-logo">Quiénes somos</h2>
        </div>
        <div className="text-about">
          <h1>Talento latinoamericano al servicio del deporte.</h1>
          <h3>
            Ciencia, experiencia y técnología llevaron a un grupo de colombianos
            a desarrollar la técnología de ONE running. Sus estudios en
            ingeniería y su experiencia como deportistas son la combinación
            perfecta para desarrollar tecnología aplicada al deporte.
          </h3>
          <p>
            Una casualidad matemática, encontrada por los dos socios fundadores,
            fue el comienzo para el uso de Inteligencia Artificial en el diseño
            de planes de entrenamiento para correr. Día tras día, más personas
            se unen al equipo de desarrollo que hoy conforma ONE running, donde
            el talento latinoamericano es nuestra principal característica.
          </p>
        </div>
        <div className="cards-flex">
          <Profiles
            img={andres}
            name="Andrés Ramírez"
            title="MSc. Ingeniería de Componentes Mecánicos"
            position="CEO & Fundador"
            descriptión="Director Ejecutivo, con más de 20 años de experiencia en el mundo del running. Altamente reconocido por aplicar modelos matemáticos efectivos al entrenamiento deportivo. Lidera la compañía, el desarrollo de producto y el levantamiento de capital."
          />
          <div className="text-one text-color">
            <AboutTexts text="Porque es posible hacer técnología de punta." />
          </div>
          <Profiles
            img={sergio}
            name="Sergio Roa"
            title="PhD. Ingeniería Mecánica en Estratégias de Carrera"
            position="CTO & Cofundador"
            descriptión="Su experiencia y estudios en la optimización de estrategías de carrera en el deporte y desarrollo de software, lo llevó a ser lider en el desarrollo de nuestro algoritmo."
          />
          <div className="text-two text-color">
            <AboutTexts text="La primera App para correr que usa Inteligencia Atificial" />
          </div>
          <Profiles
            img={luis}
            name="Luis Muñoz"
            title="PhD. Ingeniería de Sistemas Mecánicos"
            position="COO"
            descriptión="Ex profesor de la Universidad de Los Andes, con amplia experiencia en investigación, desarrollo y gestión de proyectos, lidera la gestión de datos aplicados a la Inteligencia Artificial. 
        Fue profesor asesor de tesis de nuestros socios fundadores en la carrera de posgrado."
          />
          <div className="text-three text-color">
            <AboutTexts
              text="Simple. 
        Fácil.
        Para todos los corredores"
            />
          </div>
          
        </div>
        <Gradient />
      </div>

      <Footer />
    </>
  );
};
export default AboutUs;
