import React from "react";
import "../styles/payments.css";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import typeCard from "../../../images/type-card.png";
import imgPdf from "../../../images/img-pdf.png";

const Suscription = () => {
  return (
    <>
      <Header />
      <div className="subheader">
        <h1>Administra tu suscripción</h1>
        <hr className="dotted"></hr>
        <h2>
          Eres usuario Premium desde 05/08/22.
          <br />
          Tu plan: Anual
        </h2>
      </div>
      <hr className="dotted"></hr>
      <div className="container-cards-suscription">
        <div className="card card-suscription">
          <div className="card-up">
            <h4 className="card-title">Siguiente Pago</h4>
            <h5 className="card-sub-title">Plan: Anual</h5>
            <p className="card-text">
              Fecha de pago: 05/08/23 <br />
              Valor: $131.99 <br />
              Todas las funciones habilitadas.
            </p>
          </div>
          <div className="card-down">
            <a href="/#" className="btn btn-active">
              Cambiar de plan
            </a>
            <span>¿Deseas cancelar tu suscripción?</span>
            <a href="/#" className="btn btn-deactivated">
              Cancelar suscripción
            </a>
          </div>
        </div>
        <div className="card card-suscription">
          <div className="card-up">
            <h4 className="card-title">Método de Pago</h4>
            <div className="img-cards">
              <img src={typeCard} alt="Visa o Mastercard" />
            </div>
            <div className="number-card">
              <p>
                Número de tarjeta <br />
                <span>xxxx xxxx xxxx xxxx xxxx</span>
              </p>
            </div>
            <div className="expiration-date">
              <p>
                Fecha de vencimiento <br />
                05/08/23
              </p>
            </div>
          </div>
          <div className="card-down">
            <a href="/#" className="btn btn-active">
              Cambiar método de pago
            </a>
          </div>
        </div>
        <div className="card card-suscription">
          <div className="card-up">
            <h4 className="card-title">Último Recibo</h4>
            <div className="img-pdf">
              <a href="/#">
                <img src={imgPdf} alt="Pdf" />
              </a>
              <div className="text-pdf">
                <h6>ONE running Premium</h6>
                <p>
                  Fecha de vencimiento <br />
                  05/08/23
                </p>
              </div>
            </div>
          </div>
          <div className="card-down">
            <a href="/#" className="btn btn-deactivated">
              Ver más
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Suscription;
