import React from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import "./styles/devices.css";
import Gradient from "../../globals/Gradient";
import DevicesTable from "./DevicesTable";

const Devices = () => {
  return (
    <>
      <Header />
      <div className="container-garmin">
        <div className="titules-garmin">
          <h3>ONE running App</h3>
          <h1>Dispositivos compatibles</h1>
          <hr className="dotted-garmin"></hr>
          <span> 8:00 AM GTM-5 | Agosto 22, 2022</span>
          <hr className="dotted-garmin"></hr>
          <h5>
            Necesitas tener un <strong>dispositivo deportivo</strong> para que
            nuestro{" "}
            <strong>
              algoritmo diseñe un plan de entrenamiento y aprenda de ti.
            </strong>{" "}
            En este momento, contamos con las{" "}
            <strong>siguientes conexiones:</strong>
          </h5>
        </div>
        <DevicesTable />
      </div>
      <Gradient />
      <Footer />
    </>
  );
};
export default Devices;
