import React from "react";
import check from "../../images/check-payment.png";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Alert from "../globals/Alert";
import SecondButton from "../buttons/SecondButton";
import { useNavigate } from "react-router-dom";

const GamrinSuccess = () => {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate("/");
  };
  return (
    <>
      <Header />
      <div className="container-alerts">
        <Alert
          img={check}
          title="¡Conexión con Garmin exitosa!"
          subtitle="Regresa a la App para continuar."
        />
        <div className="container-btn-alert">
          <SecondButton textButton="REGRESAR APP" onClick={handleRoute} />
        </div>
      </div>
      <Footer />
    </>
  );
};
export default GamrinSuccess;
