import React, { useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import check from "../../images/check-payment.png";
import imgError from "../../images/error-payment.png";
import FirstButton from "../buttons/FirstButton";
import { URLBACKEND } from "../../hooks/JWT";
import "./styles/extras.css";
import Alert from "../globals/Alert";
import { useNavigate } from "react-router-dom";
import SecondButton from "../buttons/SecondButton";

const WaitingList = () => {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate("/");
  };

  const [error, setError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [register, setRegister] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [data, setData] = useState({
    inputName: "",
    inputLastName: "",
    inputEmail: "",
    option: "",
  });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]:
        e.target.type === "radio" ? e.target.value : e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { inputName, inputLastName, inputEmail, option } = data;
    if (
      inputName.trim() &&
      inputLastName.trim() &&
      inputEmail.trim() &&
      option
    ) {
      setError(false);

      if (option === "Android" && !inputEmail.includes("@gmail.com")) {
        setErrorEmail(true);
      } else {
        setErrorEmail(false);
        fetch(URLBACKEND + "wait_list", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            accept: "application/json",
          },
          body:
            "first_name=" +
            inputName +
            "&last_name=" +
            inputLastName +
            "&operating_system=" +
            option +
            "&email=" +
            inputEmail,
        }).then((response) => {
          if (response.status !== 200) {
            response.json().then((data) => {
              setErrorStatus(data.detail);
              setRegister(false);
            });
          } else {
            setRegister(true);
          }
        });
      }
    } else {
      setError(true);
    }
  };

  const PintarError = () => (
    <div className="alert alert-danger">Completa los Campos</div>
  );

  const PintarErrorEmail = () => (
    <div className="error-email">
      Si seleccionaste Android registra un correo de Gmail
    </div>
  );

  return (
    <>
      <Header />
      {register ? (
         <div className="container-alerts">
         <Alert 
         img={check}
         title="¡Registro Exitoso!"
         subtitle="¡Te has registrado correctamente!"
         />
          <div className="container-btn-alert">
          <SecondButton textButton="REGRESAR" onClick={handleRoute} />
        </div>
      </div>
      ) : (
        <div className="container container-wait-list">
          <h1>
            Regístrate en nuestra <br />
            lista de espera
          </h1>
          <h2>
            Tendrás acceso a entrenamiento 100% personalizado, totalmente automático.
          </h2>
          <div className="alert-garmin">
            <img src={imgError} alt="Error Dispositivo Garmin" />
            <h3>Debes tener un dispositivo GARMIN.</h3>
          </div>
          <div className="container-form-wait-list colum">
            {error ? <PintarError /> : null}
            <form onSubmit={handleSubmit}>
              <div className="col">
                <label>Nombre</label>
                <input
                  name="inputName"
                  type="text"
                  className="form-control mb-2"
                  placeholder="Ingresa tu nombre"
                  onChange={handleChange}
                  value={data.inputName}
                />
              </div>
              <div className="col">
                <label>Apellido</label>
                <input
                  name="inputLastName"
                  type="text"
                  className="form-control mb-2"
                  placeholder=" Ingresa tu apellido"
                  onChange={handleChange}
                  value={data.inputLastName}
                />
              </div>
              <div className="col">
                <label>Correo electronico</label>
                <input
                  type="email"
                  placeholder="ejemplo@ejemplo.com"
                  name="inputEmail"
                  className="form-control mb-2"
                  onChange={handleChange}
                  value={data.inputEmail}
                />
              </div>
              {errorEmail ? <PintarErrorEmail /> : null}
              <div className="error-email">{errorStatus}</div>
              <p className="text-system">Sistema operativo</p>
              <div className="container-ios">
                <label className="form-check-label">iOS</label>
                <input
                  type="radio"
                  name="option"
                  id="exampleRadios1"
                  onChange={handleChange}
                  value="Ios"
                />
              </div>
              <div className="container-android">
                <label className="form-check-label">Android</label>
                <input
                  type="radio"
                  name="option"
                  id="exampleRadios2"
                  onChange={handleChange}
                  value="Android"
                />
              </div>
              <FirstButton textButton="REGISTRAR" />
            </form>
          </div>
          <div className="container-text">
            <h6>
              Gratis 7 días. Luego, continua entrenando desde 14.99 USD/mes.
            </h6>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};
export default WaitingList;
