import React from "react";
import DevicesBox from "./DevicesBox";
import checkStatus from "../../../images/check-payment.png";
import errorStatus from "../../../images/error-payment.png";
import garminLogo from "../../../images/garmin-logo.png";
import appleLogo from "../../../images/apple-logo.png";
import polarLogo from "../../../images/polar-logo.png";
import corosLogo from "../../../images/coros-logo.png";
import suuntoLogo from "../../../images/suunto-logo.png";

const DevicesTable = () => {
  return (
    <>
      <div className="devices-table">
        <div className="container-devices">
          <h6>Conexiones habilitadas:</h6>
          <DevicesBox
            devicesLogo={garminLogo}
            nameDevices="Garmin Connect"
            imgStatus={checkStatus}
            href="https://www.garmin.com/es-ES/c/wearables-smartwatches/"
          />
          <DevicesBox
            devicesLogo={appleLogo}
            nameDevices="Apple Watch"
            imgStatus={checkStatus}
            href="https://www.apple.com/co/watch/"
          />
        </div>
        <div className="container-devices">
          <h6>Conexiones en desarrollo:</h6>
          <DevicesBox
            devicesLogo={polarLogo}
            nameDevices="Polar Flow"
            imgStatus={errorStatus}
            href="https://www.polar.com/co-es/all-watches"
          />
          <DevicesBox
            devicesLogo={corosLogo}
            nameDevices="Coros"
            imgStatus={errorStatus}
            href="https://coros.com/"
          />
          <DevicesBox
            devicesLogo={suuntoLogo}
            nameDevices="Suunto"
            imgStatus={errorStatus}
            href="https://www.suunto.com/"
          />
        </div>
      </div>
    </>
  );
};
export default DevicesTable;
