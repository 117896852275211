import React from "react";
import graph from "../../../../images/graph-chicago.png";

const TableChicago = ({ hours, seconds, minutes, altitud }) => {
  const pace = (hours + minutes / 60 + seconds / 3600) / 24 / 10;

  const a = (6 / 60 + 25 / 3600) / 24;
  const b = (5 / 60 + 44 / 3600) / 24;
  const c = (5 / 60 + 7 / 3600) / 24;
  const d = (4 / 60 + 34 / 3600) / 24;
  const e = (4 / 60 + 0 / 3600) / 24;

  let porcentajeRef = 0;

  if (pace <= e) {
    porcentajeRef = 0.9;
  } else if (pace <= d) {
    porcentajeRef = 0.87;
  } else if (pace <= c) {
    porcentajeRef = 0.85;
  } else if (pace <= b) {
    porcentajeRef = 0.84;
  } else if (pace <= a) {
    porcentajeRef = 0.83;
  } else {
    porcentajeRef = 0.82;
  }

  const perdidaAltitud =
    1 -
    (1 -
      0.00401 * (altitud * 0.001 - 0.182) -
      0.0117 * ( altitud * 0.001 - 0.182) * (altitud * 0.001 - 0.182));

      const perdidaPorTemperatura = 0;

      const perdidaPorViento = 0.02;

  const paceRef =
    pace *
    (2 - porcentajeRef) *
    (1 - perdidaAltitud) *
    (1 + perdidaPorTemperatura) *
    (1 + perdidaPorViento);

  const decimalAHora = (decimal) => {
    var n = new Date(0, 0);
    n.setSeconds(+decimal * 24 * 60 * 60);
    return n.toTimeString().slice(0, 8);
  };

  const ritmo1a5 = paceRef * 1.035;
  const ritmo6a21 = paceRef * 1.02;
  const ritmo22a422 = paceRef * 0.98 // ritmo del 22 al 42.2

  const tiempo2 = ritmo1a5 + ritmo1a5;
  const tiempo3 = tiempo2 + ritmo1a5;
  const tiempo4 = tiempo3 + ritmo1a5;
  const tiempo5 = tiempo4 + ritmo1a5;
  const tiempo6 = tiempo5 + ritmo6a21;
  const tiempo7 = tiempo6 + ritmo6a21;
  const tiempo8 = tiempo7 + ritmo6a21;
  const tiempo9 = tiempo8 + ritmo6a21;
  const tiempo10 = tiempo9 + ritmo6a21;
  const tiempo11 = tiempo10 + ritmo6a21;
  const tiempo12 = tiempo11 + ritmo6a21;
  const tiempo13 = tiempo12 + ritmo6a21;
  const tiempo14 = tiempo13 + ritmo6a21;
  const tiempo15 = tiempo14 + ritmo6a21;
  const tiempo16 = tiempo15 + ritmo6a21;
  const tiempo17 = tiempo16 + ritmo6a21;
  const tiempo18 = tiempo17 + ritmo6a21;
  const tiempo19 = tiempo18 + ritmo6a21;
  const tiempo20 = tiempo19 + ritmo6a21;
  const tiempo21 = tiempo20 + ritmo6a21;
  const tiempo22 = tiempo21 + ritmo22a422;
  const tiempo23 = tiempo22 + ritmo22a422;
  const tiempo24 = tiempo23 + ritmo22a422;
  const tiempo25 = tiempo24 + ritmo22a422;
  const tiempo26 = tiempo25 + ritmo22a422;
  const tiempo27 = tiempo26 + ritmo22a422;
  const tiempo28 = tiempo27 + ritmo22a422;
  const tiempo29 = tiempo28 + ritmo22a422;
  const tiempo30 = tiempo29 + ritmo22a422;
  const tiempo31 = tiempo30 + ritmo22a422;
  const tiempo32 = tiempo31 + ritmo22a422;
  const tiempo33 = tiempo32 + ritmo22a422;
  const tiempo34 = tiempo33 + ritmo22a422;
  const tiempo35 = tiempo34 + ritmo22a422;
  const tiempo36 = tiempo35 + ritmo22a422;
  const tiempo37 = tiempo36 + ritmo22a422;
  const tiempo38 = tiempo37 + ritmo22a422;
  const tiempo39 = tiempo38 + ritmo22a422;
  const tiempo40 = tiempo39 + ritmo22a422;
  const tiempo41 = tiempo40 + ritmo22a422;
  const tiempo42 = tiempo41 + ritmo22a422;
  const tiempo422 = tiempo42 + ritmo22a422 / 5;

  return (
    <>
      <div className="container-21k">
        <img src={graph} alt="Grafica 42.2 k" />
        <div className="time">
          <h5>Tiempo estimado de carrera</h5>
          <h2>{decimalAHora(tiempo422)}</h2>
        </div>
        <table className="table-calculate table-bordered">
          <thead>
            <tr>
              <th scope="col">kilómetro</th>
              <th scope="col">Ritmo</th>
              <th scope="col">Tiempo</th>
            </tr>
          </thead>
          <tbody>
            <tr className="yellow">
              <th scope="row">1</th>
              <td>{decimalAHora(ritmo1a5)}</td>
              <td>{decimalAHora(ritmo1a5)}</td>
            </tr>
            <tr className="yellow">
              <th scope="row">2</th>
              <td>{decimalAHora(ritmo1a5)}</td>
              <td>{decimalAHora(tiempo2)}</td>
            </tr>
            <tr className="yellow">
              <th scope="row">3</th>
              <td>{decimalAHora(ritmo1a5)}</td>
              <td>{decimalAHora(tiempo3)}</td>
            </tr>
            <tr className="yellow">
              <th scope="row">4</th>
              <td>{decimalAHora(ritmo1a5)}</td>
              <td>{decimalAHora(tiempo4)}</td>
            </tr>
            <tr className="yellow">
              <th scope="row">5</th>
              <td>{decimalAHora(ritmo1a5)}</td>
              <td>{decimalAHora(tiempo5)}</td>
            </tr>
            <tr className="green">
              <th scope="row">6</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo6)}</td>
            </tr>
            <tr className="green">
              <th scope="row">7</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo7)}</td>
            </tr>
            <tr className="green">
              <th scope="row">8</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo8)}</td>
            </tr>
            <tr className="green">
              <th scope="row">9</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo9)}</td>
            </tr>
            <tr className="green">
              <th scope="row">10</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo10)}</td>
            </tr>
            <tr className="green">
              <th scope="row">11</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo11)}</td>
            </tr>
            <tr className="green">
              <th scope="row">12</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo12)}</td>
            </tr>
            <tr className="green">
              <th scope="row">13</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo13)}</td>
            </tr>
            <tr className="green">
              <th scope="row">14</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo14)}</td>
            </tr>
            <tr className="green">
              <th scope="row">15</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo15)}</td>
            </tr>
            <tr className="green">
              <th scope="row">16</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo16)}</td>
            </tr>
            <tr className="green">
              <th scope="row">17</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo17)}</td>
            </tr>
            <tr className="green">
              <th scope="row">18</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo18)}</td>
            </tr>
            <tr className="green">
              <th scope="row">19</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo19)}</td>
            </tr>
            <tr className="green">
              <th scope="row">20</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo20)}</td>
            </tr>
            <tr className="green">
              <th scope="row">21</th>
              <td>{decimalAHora(ritmo6a21)}</td>
              <td>{decimalAHora(tiempo21)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">22</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo22)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">23</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo23)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">24</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo24)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">25</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo25)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">26</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo26)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">27</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo27)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">28</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo28)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">29</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo29)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">30</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo30)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">31</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo31)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">32</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo32)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">33</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo33)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">34</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo34)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">35</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo35)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">36</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo36)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">37</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo37)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">38</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo38)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">39</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo39)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">40</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo40)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">41</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo41)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">42</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo42)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">42.2</th>
              <td>{decimalAHora(ritmo22a422)}</td>
              <td>{decimalAHora(tiempo422)}</td>
            </tr>
          </tbody>
        </table>
        <div className="container container-conditions">
          <h6>Condiciones de carrera</h6>
          <div className="row conditions">
            <div className="col-6 container-p">
              <p>Distancia de Carrera: 42.2 km</p>
              <p>Distancia por GPS: 43.5 km</p>
              <p>Altitud de la carrera: 180 msnm</p>
              <p>Desnivel positivo: 74 m</p>
            </div>
            <div className="col-6 container-p">
              <p>Temperatura: 6°C a 13°C</p>
              <p>Humedad: 67%</p>
              <p>Viento: 18 km/h</p>
              <p>Probabilidad de lluvia: 10%</p>
            </div>
          </div>
          <span>
            La estrategia de carrera incluye las variables más importantes que
            afectan tu rendimiento, como altitud, altimetría, temperatura,
            humedad y viento.
          </span>
         <p className="end-text">Las condiciones de carrera están dentro de un rango ideal para un rendimiento óptimo.</p> 
        </div>
      </div>
    </>
  );
};

export default TableChicago;
