import React from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import mobile from "../../../images/img-mobile.png";
import logoHome from "../../../images/logo-home.png";
import appStore from "../../../images/app-store.png";
import playStore from "../../../images/play-store.png";
import vector from "../../../images/vector.png";
import vectorBlue from "../../../images/vector-blue.png";
import mobiles from "../../../images/mobiles.png";
import calendarApp from "../../../images/calendar-app.png";
import statisticsApp from "../../../images/statistics-app.png";
import robot from "../../../images/robot.png";
import volume from "../../../images/volume.png";
import methodology from "../../../images/methodology.png";
import training from "../../../images/training.png";
import auto from "../../../images/auto.png";
import learn from "../../../images/learn.png";
import settings from "../../../images/settings.png";
import results from "../../../images/results.png";
import ThirdButton from "../../buttons/ThirdButton";
import Texts from "./Texts";
import CardsHome from "./CardsHome";
import Accordeon from "./Accordion";
import DevicesTable from "../devices/DevicesTable";
import Title from "./Title";
import List from "../../globals/List";
import "../styles/home.css";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate("/wait_list");
  };

  return (
    <>
      <Header />
      {/* First Section */}
      <div className="firt-section">
        <div className="container-title">
          <h1>ONE running App</h1>
          <h2>Con Inteligencia Artificial.</h2>
        </div>
        <div className="container-links">
          <div className="links">
            <a type="button" href="#porqueone">
              Conoce más
            </a>
            <img src={vector} alt="Seleccionar" />
          </div>
        </div>
        <img src={mobile} className="img-mobile" alt="Mobile" />
      </div>
      {/* Second Section */}
      <div className="second-section">
        <h2>
          Una app. <br />
          Una planificación. <br />
          Una personalización.
        </h2>
        <h3>
          La app para correr que aprende de ti, con Inteligencia Artificial.
        </h3>
        <img src={logoHome} alt="Logo" />
      </div>
      {/* Third Part  */}
      <div className="third-section">
        <h2>¡7 días gratis!</h2>
        <p></p>
        <div className="container-btn-store">
          <a id="AppStore1" href="https://apps.apple.com/co/app/one-running/id1632126111">
            <img alt="App Store" src={appStore} />
          </a>
          <a id="PlayStore1" href="https://play.google.com/store/apps/details?id=com.onerunning.onerunning">
            <img alt="Play Store" src={playStore} />
          </a>
        </div>
      </div>
      {/* Fourth Part  */}
      <div className="container-sections" id="porqueone">
        <Texts
          title="¿Por qué ONE?"
          description="Porque nadie es como uno.
        Porque para ONE, you are the ONE."
          subTitle="Verdadera personalización"
          href="/por-que-one"
        />
        <img className="mobiles" src={mobiles} alt="App para correr" />
      </div>
      {/* Fifth Section */}
      <div className="mg container-sections">
        <Texts
          title="Entrena fácil."
          description="Porque necesitas un entrenamiento que se adapte a ti. ¡No tú, a él!"
          subTitle="Reajustes automáticos"
          href="/entrena-facil"
        />
        <div className="imgs-fifth-section">
          <img src={calendarApp} alt="App para correr" />
          <div className="readjustment">
            <h3>¡Control a un clic!</h3>
            <p>Reajustar semana</p>
          </div>
        </div>
      </div>
      {/* Sith Section */}
      <div className="mg background-img">
        <Texts
          title="Corre mejor."
          description="No entrenes más. Entrena mejor.
          ¡Los resultados te sorprenderán!"
          subTitle="Mejores resultados."
          href="/corre-mejor"
        />
        <div className="img-sith-section">
          <img src={statisticsApp} alt="App para correr" />
        </div>
      </div>
      {/* Seventh Section */}
      <div className="seventh-section">
        <div className="container component-text">
          <h2>Escoge tu plan.</h2>
          <p>Planes aplicables luego de los 7 días gratuitos</p>
        </div>
        <div className="section-cards-home">
          <CardsHome
            cycle="Anual"
            flag="flag-blue"
            saving="Ahorra 27%"
            price={10.99}
            total={131.99}
            duration="12 meses"
          />
          <CardsHome
            cycle="Semestral"
            flag="flag-blue"
            saving="Ahorra 13%"
            price={12.99}
            total={77.99}
            duration="6 meses"
          />
          <CardsHome
            cycle="Mensual"
            price={14.99}
            total={14.99}
            duration="1 meses"
          />
        </div>
      </div>
      {/* Eighth Section */}
      <div className="container-sections list-secciton">
        <Title
          title="Características"
          subtitle="¡Tendrás la mejor técnologia!"
        />
        <div className="container-list">
          <List
            img={volume}
            title="Entrenamiento ideal"
            description="Tendrás un plan de entrenamiento balanceado acorde a tu nivel, rendimiento, disponibilidad de tiempo y evolución."
          />
          <List
            img={robot}
            title="Adaptativo en el tiempo"
            description="Nuestro algoritmo va aprendiendo de ti para que tu entrenamiento
            evolucione contigo."
          />
          <List
            img={methodology}
            title="Metodología científica"
            description="Nuestra metodología está basada en los últimos avances de la ciencia del deporte."
          />
          <List
            img={training}
            title="Entrenamiento por zonas"
            description="Aprenderás a entrenar por zonas de entrenamiento totalmente personalizadas."
          />
        </div>
      </div>
      <div className="white">
        <Title title="Ventajas" subtitle="¡Resultados nunca imaginados!" />
        <div className="container-list">
          <List
            img={auto}
            title="Autonomía"
            description="No necesitas de un entrenador. Tendrás todo lo que necesitas para correr mejor."
          />
          <List
            img={learn}
            title="Aprendizaje"
            description="Con notas simples, te llevamos a que aprendas todo lo que necesitas saber para correr mejor."
          />
          <List
            img={settings}
            title="Ajustes ilimitados"
            description="Ajusta tu plan de entrenamiento cuantas veces quieras. Nos adaptamos a ti."
          />
          <List
            img={results}
            title="Resultados"
            description="Desde las primeras semanas notarás la diferencia. Tu rendimiento mejorará sustancialmente."
          />
        </div>
      </div>
      <div className="container-devices-home">
        <Title
          title="Dispositivos"
          subtitle="Conexiones disponibles actualmente"
        />
        <DevicesTable />
      </div>
      <Accordeon />
      <Footer />
    </>
  );
};

export default HomePage;
