import React from "react";
import "./styles/buttons.css";

const SecondButton = ({ textButton, onClick }) => {
  return (
    <>
      <button type="submit" className="second-btn" onClick={onClick}>
        {textButton}
      </button>
    </>
  );
};

export default SecondButton;
