import React from "react";
import "../styles/one.css";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import TitleOne from "./TitlesOne";
import mobiles from "../../../images/mobiles.png";
import newspaper from "../../../images/newspaper.png";
import phone from "../../../images/phone.png";
import methodology from "../../../images/methodology.png";
import oneCalendar from "../../../images/one-calendar.png";
import List from "../../globals/List";
import ThirdButton from "../../buttons/ThirdButton";
import Paragraph from "./Paragraph";
import check from "../../../images/check-payment.png";
import vectorBlue from "../../../images/vector-blue.png";
import { useNavigate } from "react-router-dom";

const WhyOne = () => {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate("/wait_list");
  };

  return (
    <>
      <Header />
      <div className="container-sections-one background">
        <TitleOne title="¿Por qué ONE?" subtitle="Verdadera personalización" />
        <img className="mobiles" src={mobiles} alt="App para correr" />
      </div>
      <div className="container-sections-one section-information">
        <h3 className="small-titule">
          Porque nadie es como uno. Porque para ONE,{" "}
          <span>you are the ONE.</span>
        </h3>
        <div className="container-list">
          <List
            img={newspaper}
            title="Un solo plan de entrenamiento"
            description="Solo existe un entrenamiento correcto: el que nuestro cuerpo puede asimilar."
          />
          <List
            img={phone}
            title="En una sola App"
            description="Todo lo que necesitas para correr mejor, en una sola App. Hacemos tu vida más fácil."
          />
          <List
            img={methodology}
            title="Para cada uno de nosotros"
            description="Un plan de entrenamiento único para ti, acorde a tu experiencia, disponibilidad, rendimiento y evolución."
          />
        </div>
      </div>
      <div className="container-sections-one background">
        <div className="text-img-one">
          <TitleOne
            title="Personalización."
            text="Por medio de Inteligencia Artificial, estamos aprendiendo constantemente de ti, para que tengas un plan 100% personalizado."
          />
          <img src={oneCalendar} alt="Calendario One running" />
        </div>
      </div>
      <div className="container-paragraph">
        <TitleOne subtitleAux="Nuestro entrenamiento debe ser tan único como nosotros." />
        <Paragraph paragraph="1 de cada 2 corredores se lesionan en 1 año de entrenamiento*, debido a la mala planeación de su entrenamiento." />
        <Paragraph paragraph="Es común ver cómo corredores recreativos planean su entrenamiento basados en mitos o en creencias populares. Buscan un entrenamiento correcto, que ya le ha funcionado a alguien más, para replicarlo y obtener el mismo beneficio." />
        <Paragraph paragraph="Lo que no saben es que la ciencia es muy concreta al respecto. Cada ser humano es tan diferente, que no existe un entrenamiento que le funcione a todo el mundo. Todos debemos tener un entrenamiento totalmente personalizado para lograr nuestro objetivo." />
        <Paragraph paragraph="No importa lo que estemos buscando, hacer ejercicio por salud, bajar de peso, correr mejor, o mejorar nuestro rendimiento, todos necesitamos un plan de entrenamiento que se ajuste a estos objetivos, a nuestra disponibilidad y a nuestra evolución misma." />
        <Paragraph paragraph="Para ello, en ONE, hemos creado una tecnología deportiva que aprende de ti, y te da lo que tu cuerpo realmente necesita. Ni más ni menos, lo ideal." />
      </div>
      <div className="container-sections-one background">
        <TitleOne
          title="Objetivos personales"
          subtitleAux="ONE es para todos, sin importar tu objetivo"
        />
        <div className="container-list">
          <List
            img={check}
            title="Por salud"
            description="Si lo tuyo es correr solo por ser más saludable, debes hacer algo igual estructurado."
          />
          <List
            img={check}
            title="Para bajar de peso"
            description="Si quieres bajar de peso, antes que nada, debes tener un entrenamiento que te cuide."
          />
          <List
            img={check}
            title="Para mejorar"
            description="Si eres un corredor recurrente, que quiere hacerlo mejor, de forma estructurada y guiada, acá te enseñamos a hacerlo mejor."
          />
          <List
            img={check}
            title="Por rendimiento"
            description="Si lo tuyo es mejorar tiempos en tus carreras,  te llevamos a mejorar tu rendimiento hasta en un 7%."
          />
        </div>
        <div className="component-links">
          <div className="links">
            <a type="button" href="/entrena-facil">
              Entrena fácil
            </a>
            <img src={vectorBlue} alt="Seleccionar" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default WhyOne;
