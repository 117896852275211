import React from "react";
import { useState } from "react";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import "../../styles/pacing.css";
import TableChicago from "./TableChicago";

const Chicago = () => {
  const [value, setValue] = useState({
    distance: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    altitud: 0,
  });
  const [calculateTable, setCalculateTable] = useState(false);
  const [calculateCero, setCalculateCero] = useState(false);

  if (isNaN(value.altitud)) {
    setValue({
      ...value,
      altitud: 0,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value.distance === 42) {
      setCalculateCero(false);
      setCalculateTable(true);
    } else {
      setCalculateCero(false);
      setCalculateTable(false);
    }
    if (value.distance === 0) {
      setCalculateCero(true);
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: parseInt(e.target.value),
    });
  };

  const PintarError = () => (
    <div className="error-calculate">Registra tus datos</div>
  );

  return (
    <>
      <Header />
      <div className="container-pacing">
        <div className="texts-pacing">
          <h1>Maratón de Chicago</h1>
          <h2>Estrategia de carrera</h2>
          <h4>Ingresa los siguientes datos</h4>
        </div>
        <form onSubmit={handleSubmit} className="calculator-form">
          <div className="calculator-pacing">
            <div className="calculator-select">
              <p>Distancia de Carrera:</p>
              <select name="distance" onChange={handleChange}>
                <option value={0}>0</option>
                <option value={42.2}>42.2</option>
              </select>
              <span>km</span>
            </div>
            <div className="calculator-select">
              <p>Tu mejor tiempo 10k*:</p>
              <div className="container-select">
                <span>h</span>
                <select name="hours" onChange={handleChange}>
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                </select>
              </div>
              <div className="container-select">
                <span>mm</span>
                <select name="minutes" onChange={handleChange}>
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                  <option value={15}>15</option>
                  <option value={16}>16</option>
                  <option value={17}>17</option>
                  <option value={18}>18</option>
                  <option value={19}>19</option>
                  <option value={20}>20</option>
                  <option value={21}>21</option>
                  <option value={22}>22</option>
                  <option value={23}>23</option>
                  <option value={24}>24</option>
                  <option value={25}>25</option>
                  <option value={26}>26</option>
                  <option value={27}>27</option>
                  <option value={28}>28</option>
                  <option value={29}>29</option>
                  <option value={30}>30</option>
                  <option value={31}>31</option>
                  <option value={32}>32</option>
                  <option value={33}>33</option>
                  <option value={34}>34</option>
                  <option value={35}>35</option>
                  <option value={36}>36</option>
                  <option value={37}>37</option>
                  <option value={38}>38</option>
                  <option value={39}>39</option>
                  <option value={40}>40</option>
                  <option value={41}>41</option>
                  <option value={42}>42</option>
                  <option value={43}>43</option>
                  <option value={44}>44</option>
                  <option value={45}>45</option>
                  <option value={46}>46</option>
                  <option value={47}>47</option>
                  <option value={48}>48</option>
                  <option value={49}>49</option>
                  <option value={50}>50</option>
                  <option value={51}>51</option>
                  <option value={52}>52</option>
                  <option value={53}>53</option>
                  <option value={54}>54</option>
                  <option value={55}>55</option>
                  <option value={56}>56</option>
                  <option value={57}>57</option>
                  <option value={58}>58</option>
                  <option value={59}>59</option>
                </select>
              </div>
              <div className="container-select">
                <span>ss</span>
                <select name="seconds" onChange={handleChange}>
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                  <option value={15}>15</option>
                  <option value={16}>16</option>
                  <option value={17}>17</option>
                  <option value={18}>18</option>
                  <option value={19}>19</option>
                  <option value={20}>20</option>
                  <option value={21}>21</option>
                  <option value={22}>22</option>
                  <option value={23}>23</option>
                  <option value={24}>24</option>
                  <option value={25}>25</option>
                  <option value={26}>26</option>
                  <option value={27}>27</option>
                  <option value={28}>28</option>
                  <option value={29}>29</option>
                  <option value={30}>30</option>
                  <option value={31}>31</option>
                  <option value={32}>32</option>
                  <option value={33}>33</option>
                  <option value={34}>34</option>
                  <option value={35}>35</option>
                  <option value={36}>36</option>
                  <option value={37}>37</option>
                  <option value={38}>38</option>
                  <option value={39}>39</option>
                  <option value={40}>40</option>
                  <option value={41}>41</option>
                  <option value={42}>42</option>
                  <option value={43}>43</option>
                  <option value={44}>44</option>
                  <option value={45}>45</option>
                  <option value={46}>46</option>
                  <option value={47}>47</option>
                  <option value={48}>48</option>
                  <option value={49}>49</option>
                  <option value={50}>50</option>
                  <option value={51}>51</option>
                  <option value={52}>52</option>
                  <option value={53}>53</option>
                  <option value={54}>54</option>
                  <option value={55}>55</option>
                  <option value={56}>56</option>
                  <option value={57}>57</option>
                  <option value={58}>58</option>
                  <option value={59}>59</option>
                </select>
              </div>
            </div>
            <div className="calculator-select">
              <p>Altitud de la ciudad donde vives:</p>
              <input
                type="number"
                name="altitud"
                id="age"
                onChange={handleChange}
                min="0"
              />
              <span>msnm</span>
            </div>
          </div>
          <h6>
            *Tiempo realizado durante los últimos 6 meses, en la ciudad donde
            vives. Puede ser un estimado.
          </h6>
          <button type="submit" className="btn-calculate">
            CALCULAR
          </button>
        </form>
        {calculateTable ? (
          <TableChicago
            hours={value.hours}
            minutes={value.minutes}
            seconds={value.seconds}
            altitud={value.altitud}
          />
        ) : (
          " "
        )}
        {calculateCero ? <PintarError /> : " "}
      </div>
    </>
  );
};
export default Chicago;
