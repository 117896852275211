import React from "react";

export const PlanCard = ({
  cycle,
  saving,
  price,
  total,
  duration,
  id,
  onChange,
}) => {
  return (
    <>
    <label htmlFor={id}>
      <div className="container-cards">
        <div className="row-card">
          <div className="item1">
            <h3>{cycle}</h3>
            <div className="item2">
              <h3>Ahorra {saving}%</h3>
            </div>
          </div>
          <div className="item3">
            <input
              type="radio"
              value="anual"
              name="plan"
              id={id}
              onChange={onChange}
            />
          </div>
        </div>
        <div>
          <h4>USD ${price}/mes</h4>
          <h5>
            Pago Total: USD {total}
            <br />
            Duración: Prueba gratuita de 7 días + {duration}
            <br />
            Todas las funciones habilitadas.
          </h5>
        </div>
      </div>
      </label>
    </>
  );
};
