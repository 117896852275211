import React from "react";

const CardsHome = ({ cycle, saving, price, total, duration, flag }) => {
  return (
    <>
      <div className="home-cards">
        <div className="row-items">
          <h2>{cycle}</h2>
          <div className={flag}>
            <h3>{saving}</h3>
          </div>
        </div>
        <div>
          <h4>USD ${price}/mes</h4>
          <h5>
            Pago Total: USD {total}
            <br />
            Duración: Prueba gratuita de 7 días + {duration}
            <br />
            Todas las funciones habilitadas.
          </h5>
        </div>
      </div>
    </>
  );
};
export default CardsHome;
