import React from "react";
import check from "../../../images/check-payment.png";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Alert from "../../globals/Alert";

const Accepted = () => {
  return (
    <>
      <Header />
      <div className="container-alerts">
        <Alert
          img={check}
          title="¡Transacción Aceptada!"
          subtitle="Esperamos que sigas disfrutando de la ciencia para correr."
        />
      </div>
      <Footer />
    </>
  );
};
export default Accepted;
