import React from "react";
import "./styles/alert.css"


const Alert = ({img, title, subtitle}) => {
  return (
    <>
      <div className="container-alert">
        <div className="content-alert">
          <div className="container-img-alert">
            <img src={img} alt="Estatus Check o Error" />
          </div>
          <div className="container-text-alert">
            <h1>{title}</h1>
            <h3>{subtitle}</h3>
          </div>
        </div>
      </div>
    </>
  );
};
export default Alert;