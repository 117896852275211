var CryptoJS = require("crypto-js");

function base64url(source) {
    // Encode in classical base64
    var encodedSource = CryptoJS.enc.Base64.stringify(source);
  
    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');
  
    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');
  
    return encodedSource;
}

function createToken(){
    var expirationTime = 5*60; //in seconds
    var exp = new Date().getTime()/1000 + expirationTime;
    var data = {
        "sub": "system",
        "category": "auth",
        "exp": exp
    };
    var header = {
        "alg": "HS256",
        "typ": "JWT"
    };
    var secret = "amanu";
    var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    var encodedHeader = base64url(stringifiedHeader);
    var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    var encodedData = base64url(stringifiedData);

    var token = encodedHeader + "." + encodedData;
    var signature = CryptoJS.HmacSHA256(token, secret);
    signature = base64url(signature);

    var signedToken = token + "." + signature;
    return signedToken; 
}



export const signedToken = createToken();
export const URLBACKEND = "https://website-backend.41okmpg6anrd4.us-east-1.cs.amazonlightsail.com/";