import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/one/home/HomePage";
import AboutUs from "./components/one/about_us/AboutUs";
import NoFoundPage from "./components/globals/NoFoundPage";
import WaitingList from "./components/extras/WaitingList";
import ResetPassword from "./components/password/ResetPassword";
import GarminFails from "./components/garmin/GarminFails";
import GarminSuccess from "./components/garmin/GarminSuccess";
import PremiumPlans from "./components/payments/premium_plans/PremiumPlans";
import Accepted from "./components/payments/premium_plans/Accepted";
import Cancelled from "./components/payments/premium_plans/Cancelled";
import Suscription from "./components/payments/subscription/Subscription";
import WhyOne from "./components/one/why_one/WhyOne";
import Mmb from "./components/one/pacing/mmb/Mmb";
import Chicago from "./components/one/pacing/chicago/Chicago";
import Devices from "./components/one/devices/Devices";
import Training from "./components/one/training/Training";
import Running from "./components/one/running/Running";
import PrivacyPolicy from "./components/extras/PrivacyPolicy";
import GiftOne from "./components/extras/GiftOne";
import RedeemGift from "./components/extras/RedeemGift";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/wait_list" element={<WaitingList />} />
        <Route path="/reset_password/:token" element={<ResetPassword />} />
        <Route path="/garmin_fails" element={<GarminFails />} />
        <Route path="/garmin_success" element={<GarminSuccess />} />
        <Route path="/planes-premium" element={<PremiumPlans />} />
        <Route path="/transaccion-aceptada" element={<Accepted />} />
        <Route path="/transaccion-cancelada" element={<Cancelled />} />
        <Route path="/administrar-suscripcion" element={<Suscription />} />
        <Route path="/por-que-one" element={<WhyOne />} />
        <Route path="/quienes-somos" element={<AboutUs />} />
        <Route path="/mmb-estrategia-carrera" element={<Mmb />} />
        <Route path="/chicago-estrategia-carrera" element={<Chicago />} />
        <Route path="/dispositivos-compatibles" element={<Devices />} />
        <Route path="/entrena-facil" element={<Training />} />
        <Route path="/corre-mejor" element={<Running />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/gift-one" element={<GiftOne />} />
        <Route path="/redeem-gift" element={<RedeemGift />} />
        <Route path="*" element={<NoFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
