import React from "react";
import { useState } from "react";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { useParams } from "react-router-dom";
import Alert from "../globals/Alert";
import "./styles/password.css";
import FirstButton from "../buttons/FirstButton";
import check from "../../images/check-payment.png";

const ResetPassword = () => {
  const [password, setPassword] = useState({
    inputPassword: "",
    confirmationPassword: "",
  });
  const [errorCharacters, setErrorCharacters] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorPasswords, setErrorPasswords] = useState(false);
  const [change, setChange] = useState(false);

  const { token } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    const { inputPassword, confirmationPassword } = password;

    if (inputPassword.length < 8 || confirmationPassword.length < 8) {
      setErrorCharacters(true);
    } else if(inputPassword != confirmationPassword) {
      setErrorPasswords(true);
    } else {
      setErrorCharacters(false);
      let body = {
        'password': inputPassword,
        'token': token
      }
      fetch('https://internal.onerunning.io/api/core/auth/reset-password/', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "accept": "application/json"
        },
        body: JSON.stringify(body)
      }).then((response) => {
        if (response.status !== 200 && response.status !== 401) {
          response.json().then((data) => setErrorStatus(data.detail));
        } else if (response.status === 401) {
          setErrorStatus(
            "Token inválido o vencido. Solicita un nuevo link de recuperación de contraseña"
          );
        } else if (response.status === 200) {
          setChange(true);
        } else {
          response.json().then((data) => {
            setErrorStatus(data.detail);
          });
        }
      });
    }
    setErrorStatus("");
  };

  const handleChange = (e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };
  const ErrorCharacters = () => (
    <div className="error-email">
      La contraseña debe contener al menos 8 caracteres, una letra, y un número
    </div>
  );
  const ErrorDifferentInputPasswords = () => (
    <div className="error-email">
      Las contraseñas no coinciden
    </div>
  );

  return (
    <>
      <Header />
      {change ? (
        <div className="container-alerts">
          <Alert
            img={check}
            title="¡Cambio de contraseña exitosa!"
            subtitle="Ya puedes iniciar sesión en la App."
          />
        </div>
      ) : (
        <div className="colum reset-password">
          <form onSubmit={handleSubmit}>
            <div className="col">
              <label>Contraseña Nueva</label>
              <input
                name="inputPassword"
                type="password"
                className="form-control mb-2"
                placeholder="Ingresa tu contraseña nueva"
                onChange={handleChange}
                value={password.inputPassword}
              />
            </div>
            <div className="col">
              <label>Confirmación de contraseña</label>
              <input
                name="confirmationPassword"
                type="password"
                className="form-control mb-2"
                placeholder="Confirma tu contraseña"
                onChange={handleChange}
                value={password.confirmationPassword}
              />
            </div>
            {errorCharacters ? <ErrorCharacters /> : null}
            {errorPasswords ? <ErrorDifferentInputPasswords /> : null}
            <div className="error-email">{errorStatus}</div>
            <div className="btn-reset-password">
              <FirstButton textButton="Enviar" />
            </div>
          </form>
        </div>
      )}

      <Footer />
    </>
  );
};
export default ResetPassword;
