import React from "react";
import "../styles/one.css";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import TitleOne from "../why_one/TitlesOne";
import calendarApp from "../../../images/calendar-app.png";
import settings from "../../../images/settings.png";
import flash from "../../../images/flash.png";
import radio from "../../../images/radio.png";
import List from "../../globals/List";
import ThirdButton from "../../buttons/ThirdButton";
import Paragraph from "../why_one/Paragraph";
import check from "../../../images/check-payment.png";
import vectorBlue from "../../../images/vector-blue.png";
import { useNavigate } from "react-router-dom";

const Training = () => {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate("/wait_list");
  };

  return (
    <>
      <Header />
      <div className="container-sections-one background">
        <TitleOne title="Entrena fácil." subtitle="Reajustes automáticos" />
        <div className="imgs-fifth-section">
          <img src={calendarApp} alt="App para correr" />
          <div className="readjustment">
            <h3>¡Control a un clic!</h3>
            <p>Reajustar semana</p>
          </div>
        </div>
      </div>
      <div className="container-sections-one section-information">
        <h3 className="small-titule">
          Porque necesitas un entrenamiento que se adapte a ti ¡No tú, a él!
        </h3>
        <div className="container-list">
          <List
            img={settings}
            title="Reajusta cuando quieras"
            description="Mueve tu entrenamiento cuando quieras. Nos adaptamos a tu cumplimiento."
          />
          <List
            img={flash}
            title="No te estreses si no puedes entrenar"
            description="Si no puedes cumplir tu entrenamiento, relájate, puedes mover tu entrenamiento."
          />
          <List
            img={radio}
            title="Control a un clic"
            description="Todo lo controlas desde nuestro botón de reajuste, de forma rápida y sencilla."
          />
        </div>
      </div>
      <div className="container-sections-one background">
        <div className="text-img-one">
          <TitleOne
            title="Botón de reajuste."
            text="Con un solo clic, podrás mover tus entrenamientos sin arriesgar tu salud."
          />
          <div className="readjustment-training">
            <h3>¡Control a un clic!</h3>
            <p>Reajustar semana</p>
          </div>
        </div>
      </div>
      <div className="container-paragraph">
        <TitleOne subtitleAux="Nuestro entrenamiento debe ser saludable." />
        <Paragraph paragraph="El cumplimiento de un plan de entrenamiento es uno de los grandes problemas para los corredores recreativos. Tenemos multiples ocupaciones, y el llevar con rigurosidad un plan es dificil. " />
        <Paragraph paragraph="Cuando no cumplimos el entrenamiento, puede ser estresante y abrumador, y cometemos el error de reponer el entrenamiento de cualquier forma. Lo ideal en estos casos es recalcular todo el entrenamiento para que éste siempre sea saludable." />
        <Paragraph paragraph=" En ONE hacemos esto cada vez con un solo botón, reajustando tu semana de entrenamiento. Nuestro algoritmo vuelve a hacer todos los cálculos para que siempre estés saludable y no te estreses por no cumplir con tu entrenamiento." />
      </div>
      <div className="container-sections-one background">
        <TitleOne
          title="Nivel deportivo"
          subtitleAux="Todos necesitamos mover algún entrenamiento"
        />
        <div className="container-list">
          <List
            img={check}
            title="Principiante"
            description="Si estás comenzando a correr, naturalmente vas a tener que mover entrenamientos mientras generas el hábito de correr."
          />
          <List
            img={check}
            title="Intermedio"
            description="Si eres un corredor recurrente, el no cumplir entrenamiento te puede generar estrés. Reajustar va a darte felicidad y tranquildad."
          />
          <List
            img={check}
            title="Avanzado"
            description="Si eres un corredor consagrado, debes ser muy juicioso con tu cumplimento. Pero si no puedes hacer un entrenamiento, está nuestro reajuste."
          />
        </div>
        <div className="component-links">
          <div className="links">
            <a type="button" href="/corre-mejor">
              Corre mejor
            </a>
            <img src={vectorBlue} alt="Seleccionar" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Training;
