import React, { useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import "./styles/extras.css";
import "../globals/utils.js";
import {giftOneSubscription, storeGiftOneSubscription} from '../globals/utils.js';

export default function GiftOne() {
  let [planChoice, setPlanChoice] = useState(null);
  let [firstName, setFirstName] = useState(null);
  let [lastName, setLastName] = useState(null);
  let [userEmail, setUserEmail] = useState(null);
  let [requestResponse, setRequestResponse] = useState('');
  let [isLoading, setIsLoading] = useState(false);

  const planOptions = [
    {value: '', text: '--Selecciona un plan--'},
    {value: 1, text: 'Mensual: USD 14.99 (USD 14.99/mes)'},
    {value: 6, text: 'Semestral: USD 77.90 (USD 12.99/mes)'},
    {value: 12, text: 'Anual: USD 131.90 (USD 10.99/mes)'},
  ];
  const handleFirstNameChange = event =>{
    setFirstName(event.target.value);
  };
  const handleLastNameChange = event =>{
    setLastName(event.target.value);
  };
  const handleEmailChange = event =>{
    setUserEmail(event.target.value);
  };
  const handlePlanChoice = event =>{
    setPlanChoice(event.target.value);
  };
  async function handlePurchaseGift(){
    if(!planChoice || !userEmail || !firstName || !lastName){
      setRequestResponse('Debes llenar todos los campos');
      setTimeout(function(){
        setRequestResponse('')
      }, 3000);
      
    }else{
      setIsLoading(true);
      let r = await giftOneSubscription(userEmail, planChoice)
      let response = await r.json();

      if (r.status !== 200){
        setIsLoading(false);
        setRequestResponse(response['detail']);
        setTimeout(function(){
          setRequestResponse('')
        }, 3000);

        return
      }

      let stripe_object_id = response['stripe_response']['id']
      let r_2 = await storeGiftOneSubscription(firstName, lastName, userEmail, planChoice, stripe_object_id)

      if (r_2.status !== 200){
        setIsLoading(false);
        setRequestResponse(response['detail']);
        setTimeout(function(){
          setRequestResponse('')
        }, 3000);

        return
      }

      let response_2 = await r_2.json();
      console.log(response_2)
      setRequestResponse('Redirigiendo...')
      setTimeout(function(){
        setRequestResponse('')
      }, 2000);
      setIsLoading(false);
      // window.open(response["url"]);
      // window.location.href = response["url"]
      window.location.replace(response["url"])

    }
  };
  return (
    <>
      <Header />
      <div class="container mt-5">.</div>
      <div class="container mt-5">
        <h2>¡Regala ONE!</h2>
        <p align="justify">
          Puedes comprar una suscripción de ONE para regalársela a alguien. Recibirás en tu correo un bono de regalo con un código de redención que
          puedes reenviar a la personas que tú quieras.
        </p>
        <p align="justify">
          Si eres usuario activo de ONE, te daremos 1 mes gratis por cada regalo que des. <strong>IMPORTANTE: para recibirlo, usa tu correo ONE para la compra, se verá reflejado en las siguientes 3 semanas a la compra.</strong>
        </p>
        <p align="justify">
          <strong>Condiciones</strong>
          <ol>
            <li>La suscripción de regalo solo aplica para usuarios nuevos.</li>
            <li>El tiempo de la suscripción empezará a correr una vez se realice la redención.</li>
          </ol>
        </p>
        <p align="justify">
          Si tienes un problema con la compra o redención del bono de regalo, puedes escribirnos a info@onerunning.io.
        </p>
      </div>
      <div class="container mt-5 mb-5">
        <form>
          <div class="form-group">
            <label for="emailForSubscriptionRenew">Nombre del comprador</label>
            <input type="text" class="form-control" onChange={handleFirstNameChange} placeholder="Nombre de quien regala"></input>
          </div>
          <div class="form-group">
            <label for="emailForSubscriptionRenew">Apellido del comprador</label>
            <input type="text" class="form-control" onChange={handleLastNameChange} placeholder="Apellido de quien regala"></input>
          </div>
          <div class="form-group">
            <label for="emailForSubscriptionRenew">Email del comprador (si eres usuario ONE, usa tu correo ONE)</label>
            <input type="email" class="form-control" onChange={handleEmailChange} placeholder="Email de quien regala la suscripción"></input>
          </div>
          <div class="form-group">
            <label for="planSelection">Selecciona el plan que quieres regalar</label>
            <select class="form-control" value={planChoice} onChange={handlePlanChoice}>
              {planOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
        </form>
        <p style={{color:"green"}}>{requestResponse}</p>
        <button type="button" onClick={handlePurchaseGift} disabled={isLoading} className="btn btn-primary">
        {
          isLoading ? (
            <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Cargando...
            </>
          ) : (
          'Comprar regalo'
        )
        }
        </button>
      </div>
      <Footer />
    </>
  );
};