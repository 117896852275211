import React from "react";
import "../styles/one.css";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import TitleOne from "../why_one/TitlesOne";
import statisticsApp from "../../../images/statistics-app.png";
import volume from "../../../images/volume.png";
import training from "../../../images/training.png";
import List from "../../globals/List";
import ThirdButton from "../../buttons/ThirdButton";
import Paragraph from "../why_one/Paragraph";
import check from "../../../images/check-payment.png";
import trending from "../../../images/trending.png";
import { useNavigate } from "react-router-dom";

const Running = () => {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate("/wait_list");
  };

  return (
    <>
      <Header />
      <div className="container-sections-one background">
        <TitleOne title="Corre mejor." subtitle="Mejores resultados" />
        <div className="mobile-running">
          <img src={statisticsApp} alt="App para correr" />
        </div>
      </div>
      <div className="container-sections-one section-information">
        <h3 className="small-titule">
          No entrenes más. Entrena mejor. ¡Los resultados te sorprenderán!
        </h3>
        <div className="container-list">
          <List
            img={volume}
            title="Volumen ideal"
            description="Vas a hacer la cantidad de entrenamiento ideal para ti, para correr mejor."
          />
          <List
            img={training}
            title="Seguimiento de tu rendimiento"
            description="Nuestro algoritmo reajusta tus zonas de entrenamiento mientras vas avanzando."
          />
          <List
            img={trending}
            title="Evolución de tu condición física"
            description="Vas a poder ver de forma sencilla la evolución de tu condición física en el tiempo."
          />
        </div>
      </div>
      <div className="container-sections-one background">
        <div className="text-img-one">
          <TitleOne
            title="Resultados."
            text="Las mejoras de nuestros deportistas son superiores a las de cualquier otro método"
          />
          <div className="num-running">
            <h1>
              7 <h4>%</h4>
            </h1>
            <h2>Mejora</h2>
            <h3>en rendimiento</h3>
          </div>
          <TitleOne text="Aproximadamente 20 seg/km en el ritmo de competencia" />
        </div>
      </div>
      <div className="container-paragraph">
        <TitleOne subtitleAux="Nuestro entrenamiento debe hacernos mejorar." />
        <Paragraph paragraph="Sin importar cual sea nuestro objetivo, con cualquier plan de entrenamiento, nuestro rendimiento debe mejorar. La salud y el rendimiento están ligados entre si. Si estoy mejorando, sin lesiones, claramente el entrenamiento me está convirtiendo en una persona más saludable." />
        <Paragraph paragraph="Por más de que no sea un corredor consagrado, debería buscar estar mejorando, sino, el entrenamiento que realizo puede no ser saludable para mi." />
        <Paragraph paragraph="Un plan de entrenamiento convencional hace que un corredor mejore de un 0% a un 2% en un año de entrenamiento. Con nuestro algoritmo, logramos 7% promedio anual. ¡Increíble!" />
        <Paragraph paragraph="Para tener una idea de este número, el dopaje, logra el 10% de mejora en el rendimiento. Claramente, no queremos que llegues a este método ilegal. Eso sí, podemos acercarnos a este valor con una metodología científica y adaptativa como la de ONE." />
      </div>
      <div className="container-sections-one background">
        <TitleOne
          title="Preparación."
          subtitleAux="No importa qué distancia quieras hacer, vas a mejorar con ONE"
        />
        <div className="container-list">
          <List
            img={check}
            title="5k"
            description="Puedes comenzar con 5km, o mejorar tu velocidad para distancias superiores con esta distancia."
            subdescription="Preparación: 10 a 16 semanas"
          />
          <List
            img={check}
            title="10k"
            description="Puedes avanzar a la distancia de 10km, o seguir mejorando tu velocidad para hacer récords personales."
            subdescription="Preparación: 14 a 20 semanas "
          />
          <List
            img={check}
            title="Media Maratón"
            description="Una distancia retadora, donde puedes mejorar tu resistencia con ella, y buscar avanzar hacia la Maratón."
            subdescription="Preparación: 18 a 24 semanas"
          />
          <List
            img={check}
            title="Maratón"
            description="Es la distancia reina del atletismo, donde te llevará un proceso largo su preparación, y mejora."
            subdescription="Preparación: 22 a 28 semanas"
          />
        </div>
        <TitleOne text="Puedes preparar también distancias intermedias como de 7km, 15km, 35km, o la que quieras." />
      </div>
      <Footer />
    </>
  );
};
export default Running;
