const URL_SUBSCRIPTIONS_BACKEND = 'https://internal.onerunning.io/api/subscriptions/'

export async function giftOneSubscription(user_email, plan){
    console.log(plan);
    let price_id = ''
    if (+plan === 1){
        price_id = 'price_1ON2MwA6dWsgD23J7tGFtt4m'
    }
    if (+plan === 6){
        price_id = 'price_1ON2PKA6dWsgD23J9uywEARa'
    }
    if (+plan === 12){
        price_id = 'price_1ON2PtA6dWsgD23JqHeOP1I5'
    }

    let url = URL_SUBSCRIPTIONS_BACKEND + "stripe/get_checkout_session/?email=" + encodeURIComponent(user_email) + "&price_id=" + price_id + "&mode=payment";
    return await fetch(url, {
        method: "GET",
        headers: {
            "accept": "application/json"
        },
    })
};


export async function storeGiftOneSubscription(firstName, lastName, user_email, plan, stripe_object_id){
    let url = URL_SUBSCRIPTIONS_BACKEND + "web_purchases/gift_one/";
    let data = JSON.stringify(
        {
            "stripe_object_id": stripe_object_id,
            "customer_email": user_email,
            "customer_first_name": firstName,
            "customer_last_name": lastName,
            "gift_object": plan
        }
    )
    return await fetch(url, {
        method: "POST",
        headers: {
            "accept": "application/json",
            "Content-Type": "application/json"
        },
        body:data
    })
};


export async function redeemGift(firstName, lastName, userEmail, password, couponCode){
    let url = URL_SUBSCRIPTIONS_BACKEND + "web_purchases/redeem_gift/?coupon_code="+couponCode;
    let data = JSON.stringify(
        {
            "email": userEmail,
            "first_name": firstName,
            "last_name": lastName,
            "password": password
        }
    )
    return await fetch(url, {
        method: "POST",
        headers: {
            "accept": "application/json",
            "Content-Type": "application/json"
        },
        body:data
    })
};
