import React from "react";
import logo from "../../images/logo.png";
import nav from "../../images/nav.png";
import appStore from "../../images/app-store.png";
import playStore from "../../images/play-store.png";
import "./styles/header.css";

const Header = () => {
  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg sticky-top container-nav">
        <div className="container-fluid container-header">
          <div className="container-btn">
            <button
              className="navbar-toggler "
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img className="nav-img" src={nav} alt="Menu" />
            </button>
          </div>
          <div className="container-logo">
            <a className="navbar-brand hidden-lg-up link-logo" href="/">
              <img src={logo} alt="Brand" />
            </a>
          </div>
          <div
            className="collapse navbar-collapse container-ul"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/por-que-one">
                  ¿Por qué ONE?
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/entrena-facil">
                  Entrena Fácil
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/corre-mejor">
                  Corre Mejor
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/quienes-somos">
                  Quiénes somos
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="modal-beta">
        <div className="container-btn-store">
          <a id="AppStore1" href="https://apps.apple.com/co/app/one-running/id1632126111">
            <img alt="App Store" src={appStore} />
          </a>
          <a id="PlayStore1" href="https://play.google.com/store/apps/details?id=com.onerunning.onerunning">
            <img alt="Play Store" src={playStore} />
          </a>
        </div>
      </div>
    </>
  );
};
export default Header;
