import React from "react";
import error from "../../../images/error-payment.png";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Alert from "../../globals/Alert";

const Cancelled = () => {
  return (
    <>
      <Header />
      <div className="container-alerts">
        <Alert
          img={error}
          title="¡Transacción Cancelada!"
          subtitle="Intentalo nuevamente."
        />
      </div>
      <Footer />
    </>
  );
};
export default Cancelled;
