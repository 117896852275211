import React from "react";

const TitleOne = ({ title, subtitle, text, subtitleAux }) => {
  return (
    <>
      <div className="component-text-one">
        <h2>{title}</h2>
        <p>{subtitle}</p>
        <h6>{text}</h6>
        <h3 className="small-titule">{subtitleAux}</h3>
      </div>
    </>
  );
};
export default TitleOne;
