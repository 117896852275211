import React from "react";

const DevicesBox = ({ devicesLogo, nameDevices, imgStatus, href }) => {
  return (
    <>
      <div className="container-box">
        <div className="box-garmin">
          <img
            src={devicesLogo}
            alt="Logo del Dispositivo"
            className="logo-devices"
          />
          <a className="name-devices">{nameDevices}</a>
        </div>
        <div className="box-garmin">
          <a className="span-devices" href={href}>
            Ver dispositivos
          </a>
          <a className="span-devices" href={href}>Ver dispositivos</a>
          <a className="span-devices" href={href}>
            Ver dispositivos
          </a>
          <a className="span-devices" href={href}>Ver dispositivos</a>
          <img src={imgStatus} alt="Estatus" className="status-devices" />
        </div>
      </div>
    </>
  );
};
export default DevicesBox;
