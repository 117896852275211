import React from "react";
import graph from "../../../../images/graph10k.png";

const TableTen = ({ hours, seconds, minutes, altitud }) => {
  const pace = (hours + minutes / 60 + seconds / 3600) / 24 / 10;

  const a = (6 / 60 + 25 / 3600) / 24;
  const b = (5 / 60 + 44 / 3600) / 24;
  const c = (5 / 60 + 7 / 3600) / 24;
  const d = (4 / 60 + 34 / 3600) / 24;
  const e = (4 / 60 + 0 / 3600) / 24;

  let porcentajeRef = 0;

  if (pace <= e) {
    porcentajeRef = 1.02;
  } else if (pace <= d) {
    porcentajeRef = 1.01;
  } else if (pace <= c) {
    porcentajeRef = 1;
  } else if (pace <= b) {
    porcentajeRef = 0.99;
  } else if (pace <= a) {
    porcentajeRef = 0.98;
  } else {
    porcentajeRef = 0.96;
  }

  const perdidaAltitud =
    1 -
    (1 -
      0.00401 * (2.64 - altitud * 0.001) -
      0.0117 * (2.64 - altitud * 0.001) * (2.64 - altitud * 0.001));

  const perdidaPorTemperatura = 0.01;

  const perdidaPorViento = 0.01;

  const paceRef =
    pace *
    (2 - porcentajeRef) *
    (1 + perdidaAltitud) *
    (1 + perdidaPorTemperatura) *
    (1 + perdidaPorViento);

  const decimalAHora = (decimal) => {
    var n = new Date(0, 0);
    n.setSeconds(+decimal * 24 * 60 * 60);
    return n.toTimeString().slice(0, 8);
  };

  const ritmo1 = paceRef * 1.02; //ritmo 1, 2, 3, 4, 5, 6, 7
  const ritmo2 = paceRef * 0.98; //ritmo 8, 9, 10

  const tiempo2 = ritmo1 + ritmo1;
  const tiempo3 = tiempo2 + ritmo1;
  const tiempo4 = tiempo3 + ritmo1;
  const tiempo5 = tiempo4 + ritmo1;
  const tiempo6 = tiempo5 + ritmo1;
  const tiempo7 = tiempo6 + ritmo1;
  const tiempo8 = tiempo7 + ritmo2;
  const tiempo9 = tiempo8 + ritmo2;
  const tiempo10 = tiempo9 + ritmo2;

  return (
    <>
      <div className="container-21k">
        <img src={graph} alt="Grafica 10 k" />
        <div className="time">
          <h5>Tiempo estimado de carrera</h5>
          <h2>{decimalAHora(tiempo10)}</h2>
        </div>
        <table className="table-calculate table-bordered">
          <thead>
            <tr>
              <th scope="col">kilómetro</th>
              <th scope="col">Ritmo</th>
              <th scope="col">Tiempo</th>
            </tr>
          </thead>
          <tbody>
            <tr className="blue">
              <th scope="row">1</th>
              <td>{decimalAHora(ritmo1)}</td>
              <td>{decimalAHora(ritmo1)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">2</th>
              <td>{decimalAHora(ritmo1)}</td>
              <td>{decimalAHora(tiempo2)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">3</th>
              <td>{decimalAHora(ritmo1)}</td>
              <td>{decimalAHora(tiempo3)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">4</th>
              <td>{decimalAHora(ritmo1)}</td>
              <td>{decimalAHora(tiempo4)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">5</th>
              <td>{decimalAHora(ritmo1)}</td>
              <td>{decimalAHora(tiempo5)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">6</th>
              <td>{decimalAHora(ritmo1)}</td>
              <td>{decimalAHora(tiempo6)}</td>
            </tr>
            <tr className="blue">
              <th scope="row">7</th>
              <td>{decimalAHora(ritmo1)}</td>
              <td>{decimalAHora(tiempo7)}</td>
            </tr>
            <tr className="green">
              <th scope="row">8</th>
              <td>{decimalAHora(ritmo2)}</td>
              <td>{decimalAHora(tiempo8)}</td>
            </tr>
            <tr className="green">
              <th scope="row">9</th>
              <td>{decimalAHora(ritmo2)}</td>
              <td>{decimalAHora(tiempo9)}</td>
            </tr>
            <tr className="green">
              <th scope="row">10</th>
              <td>{decimalAHora(ritmo2)}</td>
              <td>{decimalAHora(tiempo10)}</td>
            </tr>
          </tbody>
        </table>
        <div className="container container-conditions">
          <h6>Condiciones de carrera</h6>
          <div className="row conditions">
            <div className="col-6 container-p">
              <p>Distancia de Carrera: 10 km</p>
              <p>Distancia por GPS: 10.1 km</p>
              <p>Altitud de la carrera: 2600 msnm</p>
              <p>Desnivel positivo: 15 m</p>
            </div>
            <div className="col-6 container-p">
              <p>Temperatura: 16°C a 18°C</p>
              <p>Humedad: 67%</p>
              <p>Viento: 16 km/h</p>
              <p>Probabilidad de lluvia: 30%</p>
            </div>
          </div>
          <span>
            La estrategia de carrera incluye las variables más importantes que
            afectan tu rendimiento, como altitud, altimetría, temperatura,
            humedad y viento.
          </span>
        </div>
      </div>
    </>
  );
};

export default TableTen;
