import React from "react";
import error from "../../images/error-payment.png";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Alert from "../globals/Alert";
import SecondButton from "../buttons/SecondButton";
import { useNavigate } from "react-router-dom";

const GarminFails = () => {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate("/");
  };
  return (
    <>
      <Header />
      <div className="container-alerts">
        <Alert
          img={error}
          title="¡Conexión con Garmin fallida!"
          subtitle="Intentalo nuevamente."
        />
        <div className="container-btn-alert">
          <SecondButton textButton="INTENTAR OTRA VEZ" onClick={handleRoute} />
        </div>
      </div>
      <Footer />
    </>
  );
};
export default GarminFails;
