import React, { useState } from "react";
import "../styles/payments.css";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import SecondButton from "../../buttons/SecondButton";
import { PlanCard } from "./PlanCard";

const PremiumPlans = () => {
  const [getRadio, setGetRadio] = useState(true);
  const [dataInput, setDataInput] = useState({
    id: "",
  });

  const { id } = dataInput;

  const params = new URLSearchParams(window.location.search);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (params.has("user_email") === true) {
      if (id === "") {
        setGetRadio(false);
      } else {
        setGetRadio(true);
      }
      const fetchUrlPayment =
        "https://internal.onerunning.io/api/subscriptions/stripe/get_checkout_session/?email=" + params.get("user_email") + "&price_id=" + id;
      
      let urlPayment = "/"
      
      // Fetch stripe link
      return fetch(fetchUrlPayment, {
        method: "GET",
        headers: {
          accept: 'application/json'
        }
      }).then((response) => {
        response.json().then((data) => {
          urlPayment = data['url'];
          return (document.location.href = urlPayment);
        });
      });
    } else {
      return (document.location.href = "/");
    }
  };
  const handleChange = (e) => {
    setDataInput({
      ...dataInput,
      id: e.target.id,
    });
  };
  const PintarErrorRadio = () => (
    <div className="error-email" style={{ textAlign: "center" }}>
      Selecciona un plan
    </div>
  );

  return (
    <>
      <Header />
      <div className="subheader">
        <h1>.</h1>
        <h1>Elige tu Premium</h1>
        <hr className="dotted"></hr>
        <h2>
          Entrena sin limites, diseño personalizado de <br />
          entrenamiento, reajustes autómaticos y manuales.
        </h2>
      </div>
      <hr className="dotted"></hr>
      <div className="section-cards">
        <PlanCard
          cycle="Anual"
          saving={50}
          price={10.99}
          total={131.90}
          duration="12 meses"
          id="price_1MZmMeA6dWsgD23JXeaV7Sj4"
          onChange={handleChange}
        />
        <PlanCard
          cycle="Semestral"
          saving={40}
          price={12.99}
          total={77.90}
          duration="6 meses"
          id="price_1MZmMBA6dWsgD23JToTiRTxI"
          onChange={handleChange}
        />
        <PlanCard
          cycle="Mensual"
          saving={33}
          price={14.99}
          total={14.99}
          duration="1 mes"
          valor="mensual"
          id="price_1MYzzxA6dWsgD23J1nOvb7f7"
          onChange={handleChange}
        />
      </div>
      {getRadio ? " " : <PintarErrorRadio />}
      <div className="container-btn-secondary">
        <SecondButton textButton="ADQUIRIR" onClick={handleSubmit} />
      </div>

      <Footer />
    </>
  );
};
export default PremiumPlans;
