import React, { useState } from "react";
import Header from "../layouts/Header.jsx";
import Footer from "../layouts/Footer.jsx";
import "./styles/extras.css";
import "../globals/utils.js";
import {redeemGift} from '../globals/utils.js';

export default function RedeemGift() {
  let [couponCode, setCouponCode] = useState(null);
  let [firstName, setFirstName] = useState(null);
  let [lastName, setLastName] = useState(null);
  let [userEmail, setUserEmail] = useState(null);
  let [password1, setPassword1] = useState(null);
  let [password2, setPassword2] = useState(null);
  let [requestResponse, setRequestResponse] = useState('');
  let [isLoading, setIsLoading] = useState(false);

  const handleCouponCode = event =>{
    setCouponCode(event.target.value);
  };
  const handleFirstNameChange = event =>{
    setFirstName(event.target.value);
  };
  const handleLastNameChange = event =>{
    setLastName(event.target.value);
  };
  const handleEmailChange = event =>{
    setUserEmail(event.target.value);
  };
  const handlePassword1Change = event =>{
    setPassword1(event.target.value);
  };
  const handlePassword2Change = event =>{
    setPassword2(event.target.value);
  };
  async function handleRedemption(){
    setIsLoading(true);
    if(!couponCode || !userEmail || !firstName || !lastName || !password1 || !password2){
      setRequestResponse('Debes llenar todos los campos');
      setTimeout(function(){
        setRequestResponse('')
      }, 2000);
    }else if(password1 !== password2){
      setRequestResponse('Las contraseñas no coinciden');
      setTimeout(function(){
        setRequestResponse('')
      }, 2000);
    }else{
      let r = await redeemGift(firstName, lastName, userEmail, password1, couponCode)
      console.log(r.status)
      if (r.status === 200){
        setIsLoading(false);
        setRequestResponse('¡Redención exitosa! Inicia sesión en la app con las credenciales que acabas de crear')
      }else{
        let response = await r.json();
        setIsLoading(false);
        setRequestResponse(response['detail']);
        setTimeout(function(){
          setRequestResponse('')
        }, 2000);
      }
    }
  };
  return (
    <>
      <Header />
      <div class="container mt-5">.</div>
      <div class="container mt-5">
        <h2>¡Redime tu bono de regalo de ONE!</h2>
        <p align="justify">
          Usa el código en tu bono de regalo para empezar a usar tu suscripción.
        </p>
        <p align="justify">
          Si tienes un problema, escríbenos a info@onerunning.io.
        </p>
      </div>
      <div class="container mt-5 mb-5">
        <form>
          <div class="form-group">
            <label for="formRedemption">Código del bono de regalo</label>
            <input type="text" class="form-control" onChange={handleCouponCode} placeholder="Código cupón"></input>
          </div>
          <div class="form-group">
            <label for="formRedemption">Tu nombre</label>
            <input type="text" class="form-control" onChange={handleFirstNameChange} placeholder="Nombre"></input>
          </div>
          <div class="form-group">
            <label for="formRedemption">Tu apellido</label>
            <input type="email" class="form-control" onChange={handleLastNameChange} placeholder="Apellido"></input>
          </div>
          <div class="form-group">
            <label for="formRedemption">Tu email</label>
            <input type="email" class="form-control" onChange={handleEmailChange} placeholder="Email"></input>
          </div>
          <div class="form-group">
            <label for="formRedemption">Contraseña (al menos 8 caracteres, una letra y un número)</label>
            <input type="password" class="form-control" onChange={handlePassword1Change} placeholder="Contraseña"></input>
          </div>
          <div class="form-group">
            <label for="formRedemption">Repite tu contraseña</label>
            <input type="password" class="form-control" onChange={handlePassword2Change} placeholder="Repite contraseña"></input>
          </div>
        </form>
        <p style={{color:"green"}}>{requestResponse}</p>
        <button type="button" onClick={handleRedemption} disabled={isLoading} className="btn btn-primary">
          {
            isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Cargando...
              </>
            ) : (
            'Redimir bono'
          )
          }
        </button>
      </div>
      <Footer />
    </>
  );
};