import React from "react";
import vectorBlue from "../../../images/vector-blue.png";

const Texts = ({ title, description, subTitle,href }) => {
  return (
    <>
      <div className="container component-text">
        <h2>{title}</h2>
        <p>{description}</p>
        <h3>{subTitle}</h3>
        <div className="component-links">
          <div className="links">
            <a type="button" href={href}>
              Conoce más
            </a>
            <img src={vectorBlue} alt="Seleccionar" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Texts;
