import React, { useState } from "react";
import down from "../../../images/down.png";
import up from "../../../images/up.png";

const Accordeon = () => {
  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  return (
    <>
      <div className="container-fluid container-nineth-part" id="secondback">
        <div className="align-self-center nineth-part">
          <h2>Preguntas</h2>
          <div className="accordion">
            {data.map((item, i) => (
              <div key={i}>
                <div className="item" onClick={() => toggle(i)}>
                  <div className="title-accordion">
                    <h2>{item.question}</h2>
                    <span>
                      {selected === i ? <img src={up} alt="Arriba"/> : <img src={down} alt="Abajo"/>}
                    </span>
                  </div>
                  <div className={selected === i ? "content show" : "content"}>
                    <p>{item.answer1}</p>
                    <p>{item.answer2}</p>
                    <div className="list-items">
                      <span>{item.answer3.item1}</span>
                      <span>{item.answer3.item2}</span>
                      <span>{item.answer3.item3}</span>
                      <span>{item.answer3.item4}</span>
                    </div>
                    <p>{item.answer4}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Accordeon;

const data = [
  {
    question: "¿Es para todo tipo de corredores?",
    answer1:
      "Si, es para todo tipo de corredores. No importa tu nivel, One aprenderá de ti para llevarte por el mejor camino para cumplir tus objetivos.",
    answer2: "Objetivos:",
    answer3: {
      item1: "1. Mejorar tu salud o empezar a correr.",
      item2: "2. Lograr hacer una carrera.",
      item3: "3. Mejorar tiempos o rendimiento.",
      item4: "4. Alto rendimiento.",
    },
    answer4: "",
  },
  {
    question: "¿El plan se va recalculando con el tiempo?",
    answer1:
      " Sí. Continuamente One está revisando tu cumplimiento, sensaciones y datos para recalcular tu plan a corto y mediano plazo.",
    answer2:
      "One calcula tu fatiga y tu asimilación de entrenamiento constantemente para mantenerte saludable en tu proceso de entrenamiento.",
    answer3: {
      item1: "",
      item2: "",
      item3: "",
      item4: "",
    },
    answer4: "",
  },
  {
    question: "¿Se puede usar con cualquier dispositivo deportivo?",
    answer1: "One tiene conexión directa con:",
    answer2: "",
    answer3: {
      item1: "Dispositivos Garmin(Garmin Connect).",
      item2: "",
      item3: "",
      item4: "",
    },
    answer4:
      "",
  },
  {
    question: "¿Cuánto dura la versión gratuita?",
    answer1:
      "El periodo gratuito de One es de 7 días",
    answer2: "",
    answer3: {
      item1: "",
      item2: "",
      item3: "",
      item4: "",
    },
    answer4: "",
  },
];
